<template>
    <Header />
    <div>
        <div class="container mt-5">
            <div class="row">
                <div class="col-md-8 mx-auto">
                    <form @submit.prevent="submitForm">
                        <div class="mb-3">
                            <label for="to" class="form-label">Choose from customers:</label>
                            <select class="form-select" @change="fillEmail">
                                <option></option>
                                <option v-for="(customerEmail) in customerEmails" :key="customerEmail.id">
                                    {{ customerEmail }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="to" class="form-label">To:</label>
                            <input type="email" class="form-control" id="to" name="to" v-model="emailForm.email">
                        </div>
                        <div class="mb-3">
                            <label for="subject" class="form-label">Subject:</label>
                            <input type="text" class="form-control" id="subject" name="subject" v-model="emailForm.subject">
                        </div>
                        <div class="mb-3">
                            <label for="message" class="form-label">Message:</label>
                            <ckeditor id="message" :editor="editor" v-model="emailForm.body"></ckeditor>
                        </div>
                        <button type="submit" class="btn btn-primary" :disabled="isSubmitted">
                            <span v-show="isSubmitted" class="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true"></span>
                            Send
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ApiService from '@/services/ApiService'
import { useToast } from "vue-toastification";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from "@ckeditor/ckeditor5-vue"
import Header from '@/components/panel/Header.vue'

export default {
    components: {
        Header,
        ckeditor: CKEditor.component
    },
    data() {
        return {
            editor: ClassicEditor,
            emailForm: {
                email: '',
                subject: '',
                body: '',
            },
            customerEmails: [],
            loading: true,
            toast: useToast(),
            isSubmitted: false
        }
    },
    async created() {
        const response = await ApiService.getCustomerEmails()
        this.customerEmails = response
    },
    methods: {
        async submitForm() {
            if (!this.emailForm.email) {
                this.toast.error('Please enter your email address')
                return
            }
            if (!this.emailForm.subject) {
                this.toast.error('Please enter the subject')
                return
            }
            this.isSubmitted = true
            try {
                await ApiService.sendEmail(this.emailForm)
                this.toast.success('Email sent successfully')
            } catch (error) {
                console.log(error);
                this.toast.error('Error sending email')
            }
            this.isSubmitted = false
        },
        fillEmail(event) {
            this.emailForm.email = event.target.value
        }
    }
}
</script>
<style>
.ck-editor__editable_inline {
    height: 250px;
    overflow-y: auto;
}
</style>