<template>
  <div class="wrapper">
    <div class="header">
      <h1>Utku Okutan</h1>
    </div>
    <div class="main">
      <div>
        <p>I am a backend developer focused on building powerful and scalable web applications. I specialize in PHP and Node.js, crafting efficient and high-performance backend solutions. Additionally, I have experience working with Transact-SQL (T-SQL) for database management and optimization. I am also proficient in leveraging AWS services to build secure and scalable cloud-based applications.</p>
        <p>I am an expert in OpenCart and have developed dozens of extension modules for the platform. I have extensive experience with REST API systems and have recently been exploring GraphQL to enhance API development and efficiency.</p>
        <p>If you have a project in mind or are considering working with me, feel free to reach out at <a href="mailto:contact@utkuokutan.com">contact@utkuokutan.com</a>. I’m here to develop reliable and efficient backend solutions tailored to your needs.</p>

        <div class="clients">
          <h3>Some of the clients I’ve worked with</h3>
          <p>
            Alavya Hotel, BAKSI Museum, Polimeks Holding, Atlantik Holding, Most Productions, CSTEM, Orient Handmade Carpets, Dardanel, Seasong Tours, Sea Homes Pasalimani, Vibrant Turkey, Advanco, Tuba Buyukustun, Yasemin Ozbudun, Ugur Isik, Altay Biber, Aysegul Dinckok, Timucin Oral, Ediz Pinar.
          </p>
        </div>
      </div>
      <div class="text-center">
        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
          CONTACT ME
        </button>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div ref="myModal" class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="staticBackdropLabel">Contact form</h1>
          <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="mb-3">
              <label for="email" class="form-label">Your email</label>
              <input type="email" class="form-control" id="email" name="to" v-model="contactForm.email">
            </div>
            <div class="mb-3">
              <label for="subject" class="form-label">Subject</label>
              <input type="text" class="form-control" id="subject" name="subject" v-model="contactForm.subject">
            </div>
            <div class="mb-3">
              <label for="message" class="form-label">Message</label>
              <textarea class="form-control" id="message" name="message" v-model="contactForm.body" rows="3"></textarea>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-lg btn-primary" @click="submitForm" :disabled="isSubmitted">SUBMIT</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/services/ApiService'
import { useToast } from "vue-toastification";

export default {
    data() {
        return {
            contactForm: {
                email: '',
                subject: '',
                body: '',
            },
            toast: useToast(),
            isSubmitted: false
        }
    },
    methods: {
        async submitForm() {
            if (!this.contactForm.email) {
                this.toast.error('Please enter an email address')
                return
            }
            if (!this.contactForm.subject) {
                this.toast.error('Please enter a subject')
                return
            }
            if (!this.contactForm.body) {
                this.toast.error('Please enter your message')
                return
            }

            this.isSubmitted = true

            try {
                await ApiService.sendContactEmail(this.contactForm)
                this.toast.success('Email sent successfully')
                this.contactForm = {
                    email: '',
                    subject: '',
                    body: '',
                }
                this.$refs.myModal.querySelector('.btn-close').click()
            } catch (error) {
                console.log(error);
                this.toast.error('Error sending email')
            }
            this.isSubmitted = false
        },
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Playfair+Display:wght@500&display=swap');

/* Global styles */
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  background-color: #EAEAEA;  /* Yeni arka plan rengi */
  color: #333;
}

a {
  color: #3498DB;  /* Yeni link rengi */
}

a:hover {
  color: #2980B9;  /* Hover rengi */
}

p {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.7;
}

/* Main content */
.wrapper {
  font-family: 'Open Sans', sans-serif;
  line-height: 1.8;
}

.main {
  max-width: 900px;
  margin: 90px auto 40px;
  font-size: 18px;
  line-height: 1.7;
}

.clients {
  margin-top: 40px;
}

/* Header */
.header {
  background-color: #2C3E50;  /* Yeni başlık arka plan rengi */
  color: white;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 2px;
}

.header h1 {
  font-family: 'Playfair Display', serif;
  font-size: 36px;
}

.text-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Button styling */
.btn-primary {
  background-color: #2980B9;  /* Yeni buton rengi */
  border: none;
  padding: 14px 35px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #2471A3;
}

.modal-header {
  background-color: #34495E;
  color: white;
}

.modal-title {
  font-size: 24px;
}

.modal-content {
  border-radius: 10px;
}

.modal-body {
  font-size: 16px;
}

@media only screen and (max-width: 768px) {
  .header {
    padding: 25px;
  }

  .main {
    margin: 20px auto;
    padding: 20px;
    font-size: 16px;
  }

  .header h1 {
    font-size: 28px;
  }

  .btn-primary {
    font-size: 16px;
    padding: 10px 24px;
  }
}

</style>
